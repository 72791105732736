// 新增/编辑 走访人员信息
const addOrUpVisPerInfoUrl = `/gateway/hc-arch/visiting/addOrUpVisPerInfo`;
// 删除走访人员信息
const deleteVisPerInfoUrl = `/gateway/hc-arch/visiting/deleteVisPerInfo`;
// 获取走访人员信息列表
const getVisPerInfoUrl = `/gateway/hc-arch/visiting/getVisPerInfo`;
// 导入文件
const importUrl = `/gateway/hc-arch/buildingRegulate/import`;
// 获取全部可查询字段
const getSearchWordsUrl = `/gateway/hc-arch/buildingRegulate/getSearchWords`;
// 编辑企业信息
const updateBusinessInfoUrl = `/gateway/hc-arch/buildingRegulate/updateBusinessInfo`;
// 删除企业营业收入
const deleteBusinessIncomeUrl = `/gateway/hc-arch/buildingRegulate/deleteBusinessIncome`;
// 查询企业信息
const getBusinessInfosUrl = `/gateway/hc-arch/buildingRegulate/getBusinessInfos`;
// 删除企业信息
const deleteBusinessInfoUrl = `/gateway/hc-arch/buildingRegulate/deleteBusinessInfo`;
// 获取企业营业收入
const getBusinessIncomeUrl = `/gateway/hc-arch/buildingRegulate/getBusinessIncome`;
// 导出企业信息
const exportBusinessInfoUrl = `/gateway/hc-arch/buildingRegulate/exportBusinessInfo`;
// 导出企业营业收入信息
const exportBusinessIncomeUrl = `/gateway/hc-arch/buildingRegulate/exportBusinessIncome`;
// 导出企业税收信息
const exportBusinessRevenueUrl = `/gateway/hc-arch/buildingRegulate/exportBusinessRevenue`;
// 删除企业税收
const deleteBusinessRevenueUrl = `/gateway/hc-arch/buildingRegulate/deleteBusinessRevenue`;
// 获取企业税收
const getBusinessRevenueUrl = `/gateway/hc-arch/buildingRegulate/getBusinessRevenue`;
// 发送短信
const sendSmsUrl = `/gateway/hc-arch/buildingRegulate/sendSms`;
// 获取楼宇信息
const getBuildingInfoUrl = `/gateway/hc-arch/buildingRegulate/getBuildingInfo`;
// 保存上一次返回字段
const saveLastWordsUrl = `/gateway/hc-arch/buildingRegulate/saveLastWords`;
// 获取上一次保存字段
const getLastWordsUrl = `/gateway/hc-arch/buildingRegulate/getLastWords`;

export {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
};
